import { FunctionComponent } from "react";

export type MobileLogoWidgetComponent = FunctionComponent<{
  onClick: () => void;
}>;

export type WidgetComponent = {
  Card: FunctionComponent;
  MobileLogo: MobileLogoWidgetComponent;
};

type WidgetKey = "onboardingTracker" | "postTank";

export const WIDGETS: Record<WidgetKey, WidgetComponent> = {
  onboardingTracker: {
    Card: () => "Onboarding tracking placeholder",
    MobileLogo: (props) => <div {...props}>Onboarding</div>,
  },
  postTank: {
    Card: () => "Post tank placeholder",
    MobileLogo: (props) => <div {...props}>Post Tank</div>,
  },
};
