import { MenuOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import BusinessSelector from "components/BusinessSelector";
import SidebarBottomMenu from "components/SidebarNav/SidebarBottomMenu";
import SidebarNavItems from "components/SidebarNav/SidebarNavItems";
import useAppContext from "config/AppContext/useAppContext";
import LogoLink from "config/theme/LogoLink";
import { MOBILE_SIDEBAR_BREAKPOINT } from "fixtures/globalConstants";
import { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useLocation } from "react-use";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";

const MobileSidebarMenu = () => {
  const {
    sidebarCollapsedState: [sidebarCollapsed, setSidebarCollapsed],
  } = useAppContext();
  const { pathname } = useLocation();

  const currentBusiness = useAppSelector(currentBusinessGetter);

  useEffect(() => {
    window.innerWidth < MOBILE_SIDEBAR_BREAKPOINT && setSidebarCollapsed(true);
  }, [pathname, currentBusiness.id]);

  return (
    <>
      <Button
        type="text"
        onClick={() => setSidebarCollapsed(false)}
        icon={<MenuOutlined className="menu-icon" />}
      />
      <Drawer
        open={!sidebarCollapsed}
        rootClassName="lg:hidden"
        onClose={() => setSidebarCollapsed(true)}
        closeIcon={null}
        title={
          <div className="mobile-sidebar-menu__header -my-1 -mr-2">
            <LogoLink className="!px-0 hover:text-antd-colorTextBase" />
            <Button
              type="text"
              onClick={() => setSidebarCollapsed(true)}
              icon={<MdClose size={18} />}
            />
          </div>
        }
        className="mobile-sidebar-menu !bg-antd-colorBgContainer"
      >
        <div className="flex flex-col h-full">
          <BusinessSelector />
          <SidebarNavItems />
          <SidebarBottomMenu />
        </div>
      </Drawer>
    </>
  );
};

export default MobileSidebarMenu;
