import classNames from "classnames";
import useAppContext from "config/AppContext/useAppContext";
import {
  useTypedFeatureIsOn,
  useTypedFeatureValue,
} from "config/Growthbook/growthbookUtils";
import { FADE_IN_ANIMATION } from "fixtures/motionAnimations";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store";
import { currentBusinessGetter } from "store/user/userSlice";
import { WIDGETS } from "./widgetFixtures";

const WIDGET_MODAL_ANIMATION: MotionProps = {
  initial: { transform: "translateY(-10px) translateX(-50%)", opacity: 0 },
  animate: { transform: "translateY(0px) translateX(-50%)", opacity: 1 },
  exit: { transform: "translateY(-10px) translateX(-50%)", opacity: 0 },
};

const WidgetDisplay = ({ isMobileLogo }: { isMobileLogo?: boolean }) => {
  const { onboarding_tracking } = useAppSelector(currentBusinessGetter);

  const {
    overrideMobileHeaderState: [, setOverrideMobileHeader],
  } = useAppContext();
  const [showMobileCard, setShowMobileCard] = useState(false);

  const onboardingTrackerEnabled =
    useTypedFeatureIsOn("onboarding-tracker") &&
    // TODO: Types added in SWE-2267
    (onboarding_tracking?.showMilestoneTracker as boolean);
  const postTankEnabled = useTypedFeatureValue("post-tank", "OFF") !== "OFF";

  const Widget = useMemo(
    () =>
      onboardingTrackerEnabled
        ? WIDGETS.onboardingTracker
        : postTankEnabled
          ? WIDGETS.postTank
          : null,
    [onboardingTrackerEnabled, postTankEnabled]
  );

  useEffect(() => {
    if (Widget && isMobileLogo) {
      setOverrideMobileHeader(true);
    }

    return () => {
      isMobileLogo && setOverrideMobileHeader(false);
    };
  }, [Widget, isMobileLogo]);

  return (
    Widget && (
      <>
        {isMobileLogo && (
          <Widget.MobileLogo onClick={() => setShowMobileCard(true)} />
        )}

        <AnimatePresence>
          {showMobileCard && (
            <motion.div
              key="mask"
              onClick={() => setShowMobileCard(false)}
              {...FADE_IN_ANIMATION}
              className="bg-antd-colorBgMask fixed h-dvh w-dvw top-0 left-0 z-antd-popup"
            />
          )}

          {(showMobileCard || !isMobileLogo) && (
            <motion.div
              key="card"
              {...(isMobileLogo && WIDGET_MODAL_ANIMATION)}
              className={classNames(
                "[&_>_*]:space-y-4 p-4 rounded-lg bg-antd-colorBgContainer text-sm shadow-sm border border-antd-colorBorderSecondary",
                showMobileCard
                  ? "fixed top-4 left-1/2 z-antd-popup"
                  : "max-lg:hidden mx-2"
              )}
            >
              <Widget.Card />
            </motion.div>
          )}
        </AnimatePresence>
      </>
    )
  );
};

export default WidgetDisplay;
