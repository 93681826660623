import MobileSidebarMenu from "components/SidebarNav/MobileSidebarMenu";
import WidgetDisplay from "components/SidebarNav/sidebarWidgets/WidgetDisplay";
import useAppContext from "config/AppContext/useAppContext";
import { useTypedFeatureIsOn } from "config/Growthbook/growthbookUtils";
import LogoLink from "config/theme/LogoLink";
import useUserPermissions from "config/UserPermissionsContext/useUserPermissions";
import { POSTS_PAGE_ROUTE } from "pages/Posts/PostsPage";
import { useLocation } from "react-router-dom";
import CreatePostDropdown from "./CreatePostDropdown";

const MobileHeader = () => {
  const postCalendar = useTypedFeatureIsOn("post-calendar");

  const {
    headerChildrenState: [headerChildren],
    overrideMobileHeaderState: [overrideMobileHeader],
  } = useAppContext();

  const { hasPermission } = useUserPermissions();
  const { pathname } = useLocation();

  return (
    <div className="mobile-header w-full lg:!hidden">
      <div className="mobile-header__top py-3 pl-6 pr-2">
        <div className="flex gap-3 items-center">
          <WidgetDisplay isMobileLogo />
          {!overrideMobileHeader && <LogoLink className="!px-0" />}
          {!postCalendar && pathname === POSTS_PAGE_ROUTE
            ? hasPermission("upload_custom_post_write") && (
                <CreatePostDropdown />
              )
            : pathname === "/dashboard" && headerChildren}
        </div>
        <MobileSidebarMenu />
      </div>
      <div className="flex gap-4 justify-between bg-antd-colorFillTertiary overflow-auto">
        {pathname !== "/dashboard" && headerChildren}
      </div>
    </div>
  );
};

export default MobileHeader;
